$(document).ready(function () {
  $('form').submit(function (e) {
    $("button[type='submit']").attr("disabled", "disabled").addClass("disabled");
    $(this).find('.form-group').css("visibility", "hidden");
    $(this).find('.mydometicLoginComponent-loader').css("visibility", "visible");
  });
  if (window.location.href.indexOf('?error=') !== -1) {
    setTimeout(function () {
      scrollToElement($(".mydometicLoginComponent"));
    }, 200);
  }
});